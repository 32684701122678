import React from 'react'
import { graphql, StaticQuery, Link } from "gatsby"
import NewLogo from '../common/NewLogo'
import FooterMenuList from './MenuList/FooterMenuList'

const FooterNav = () => (
  <StaticQuery 
    query={query}
    render={
      data => {
        const siteTitle = data.site.siteMetadata.title

        return (
          <footer>
            <div className='w-full lg:w-11/12 lg:flex lg:ml-auto'>
              <div className='w-full lg:w-3/6 lg:py-16 pt-16 px-4 lg:px-0 text-center lg:text-left'>
                <div className='w-64 mb-8 sm:mx-auto md:mx-auto'>
                  <Link to='/'>
                    <NewLogo />
                  </Link>
                </div>

                <h2 className='text-gray-900 font-bold text-xl mb-2'>Interpacific Asset Management</h2>
                <p className='text-gray-800'>DRE License #01073855</p>
                <p className='text-gray-800 mb-12 lg:mb-20'>5505 Garden Grove Blvd., Suite 150, Westminster, CA 92683</p>

                <p className='sm:hidden md:hidden text-xs text-gray-500'>© {new Date().getFullYear()} {siteTitle}</p>
              </div>

              <FooterMenuList />

              <div className='bg-gray-200 sm:w-full md:w-full w-2/6 pl-16 py-24 sm:text-center md:text-center sm:px-4 md:px-4 sm:py-16 md:py-16'>
                <h2 className='text-3xl text-gray-900 font-bold mb-4'>Contact Us</h2>
                <p className='text-gray-900 mb-4'>Feel free to give us a call at</p>
                <a href='tel:714-891-8804' className='block text-2xl text-gray-900 font-bold hover:text-blue-900'>(714) 891-8804</a>
                <a href='tel:800-714-0200' className='block text-2xl text-gray-900 font-bold hover:text-blue-900'>(800) 714-0200</a>
                <p className='text-gray-900 mt-4 mb-16'>or <Link to='/request-info' className='font-bold underline text-blue-900 hover:text-gray-900'>send us a message</Link>.</p>

                <p className='text-sm text-gray-400'>Site by <a href='https://www.fastweb.dev' target='_blank' rel='noopener noreferrer' className='font-bold underline hover:text-gray-900'>fastweb.dev</a></p>
              </div>
            </div>
          </footer>
        )
      }
    }
  />
)

export default FooterNav

const query = graphql`
  query FooterNavQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`