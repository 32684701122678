import React from "react"
import { Link } from 'gatsby'

const FooterMenuList = () => (
  <div className='w-full lg:w-1/6 lg:pt-32 px-4 pb-12 text-center lg:text-left'>
    <Link to='/' key='1' className='block text-lg lg:text-sm text-gray-900 underline mb-4 lg:mb-2'>Home</Link>

    <Link to='/about' key='3' className='block text-lg lg:text-sm text-gray-900 underline mb-4 lg:mb-2'>About Us</Link>

    <Link to='/request-service' key='9' className='block text-lg lg:text-sm text-gray-900 underline mb-4 lg:mb-2'>Schedule Service</Link>

    <a href='https://www.paylease.com/index_out.php?pm_id=4710652' key='10' target='_blank' rel='noopener noreferrer' className='block text-lg lg:text-sm text-gray-900 underline mb-4 lg:mb-2'>Make a Payment</a>

    <Link to='/privacy' key='11' className='block text-lg lg:text-sm text-gray-900 underline mb-4 lg:mb-2'>Privacy Policy</Link>

    <Link to='/terms' key='12' className='block text-lg lg:text-sm text-gray-900 underline mb-4 lg:mb-2'>Terms of Service</Link>

    <Link to='/accessibility' key='13' className='block text-lg lg:text-sm text-gray-900 underline'>Accessibility</Link>
  </div>
)

export default FooterMenuList