import React from "react"
import { Link } from 'gatsby'

const MobileMenuList = ({ close }) => (
  <div className='w-full flex flex-col p-0'>
    <Link to='/' onClick={close} key='1' className='text-blue-900 font-bold text-2xl mb-2 uppercase'>Home</Link>

    <div key='2' className='text-blue-900 font-bold text-2xl uppercase'>About</div>
    <hr />
    <Link to='/about' onClick={close} key='3' className='text-blue-900 text-2xl ml-4 mt-2 mb-2'>Overview</Link>
    <Link to='/mission-statement' onClick={close} key='4' className='text-blue-900 text-2xl ml-4 mt-2 mb-2'>Mission Statement</Link>
    <Link to='/amo-management' onClick={close} key='5' className='text-blue-900 text-2xl ml-4 mt-2 mb-4'>AMO Management</Link>

    <div key='6' className='text-blue-900 font-bold text-2xl uppercase'>Services</div>
    <hr />
    <Link to='/community-associations' onClick={close} key='7' className='text-blue-900 text-2xl ml-4 mt-2 mb-2'>Community Associations</Link>
    <Link to='/services-commercial' onClick={close} key='9' className='text-blue-900 text-2xl ml-4 mt-2 mb-2'>Commercial Services</Link>
    <Link to='/services-residential' onClick={close} key='10' className='text-blue-900 text-2xl ml-4 mt-2 mb-4'>Residential Services</Link>

    <Link to='/owner-portal' onClick={close} key='11' className='text-blue-900 font-bold text-2xl mb-2 uppercase'>HOA Owner Portal</Link>
    <Link to='/request-service' onClick={close} key='11' className='text-blue-900 font-bold text-2xl mb-2 uppercase'>Schedule Service</Link>

    <a href='https://www.paylease.com/index_out.php?pm_id=4710652' onClick={close} key='12' target='_blank' rel='noopener noreferrer' className='text-blue-900 font-bold text-2xl mb-2 uppercase'>Make a Payment</a>

    <Link to='/request-info' onClick={close} key='13' className='text-blue-900 font-bold text-2xl mb-2 uppercase'>Request Information</Link>

    <Link to='/contact' onClick={close} key='14' className='text-blue-900 font-bold text-2xl mb-2 uppercase'>Contact</Link>
  </div>
)

export default MobileMenuList