import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const NewLogo = () => {
  const data = useStaticQuery(graphql`
    query NewLogoQuery {
      logoNew: file(relativePath: { eq: "header/iam-new-logo-1-small.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const imageLogoNew = data.logoNew.childImageSharp.fluid

  return (
    <Img fluid={imageLogoNew} alt='Interpacific Asset Management logo' className='object-fill w-full h-auto' />
  )
}

export default NewLogo